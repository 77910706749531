<template>
  <v-select
    v-model="localSelectedValue"
    :items="selectedItems"
    item-value="value"
    item-text="text"
    single-line
    dense
    solo
    hide-details="auto"
  ></v-select>
</template>

<script>
import {
  mmpiLbIdRange,
  nuclearLbIdRange,
  reportModeSelectList,
  mmpiReportModeSelectList,
  nuclearReportModeSelectList
} from "@/utils/constants/report";

export default {
  props: {
    selectedValue: {
      type: String
    },
    lbId: {
      type: Number,
      required: true
    }
  },

  model: {
    prop: "selectedValue",
    event: "change"
  },

  data() {
    return {
      reportModeList: reportModeSelectList,
      mmpiLbIdList: mmpiLbIdRange,
      mmpiReportModeList: mmpiReportModeSelectList,
      nuclearLbIdList: nuclearLbIdRange,
      nuclearReportModeList: nuclearReportModeSelectList,
      localSelectedValue: this.selectedValue
    };
  },

  watch: {
    selectedValue(newVal) {
      this.localSelectedValue = newVal;
    },
    localSelectedValue(newVal) {
      this.$emit("change", newVal);
    }
  },

  computed: {
    selectedItems() {
      if (this.mmpiLbIdList.includes(this.lbId)) {
        return this.mmpiReportModeList;
      } else if (this.nuclearLbIdList.includes(this.lbId)) {
        return this.nuclearReportModeList;
      } else {
        return this.reportModeList;
      }
    }
  }
};
</script>
