<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card class="fixed-card">
      <v-card-title ref="cardTitle">
        案例管理
        <AppTooltipBtn
          btn-class="ml-5 my-2"
          icon="mdi-help-circle-outline"
          tooltip="团体报告帮助"
          @click="isShowHelpDialog = true"
        />
        <AppTooltipBtn
          rounded
          color="primary"
          icon="mdi-file-chart-outline"
          label="团体报告"
          tooltip="生成团体报告，只能选择同一个量表进行"
          @click="showGroupReport"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-folder-zip-outline"
          label="批量下载报告"
          tooltip="把选中的案例，批量导出报告并下载为zip压缩包"
          @click="showReportBulkDownloadDialog(reportBulkDownloadTypeDict.zip)"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-file-download-outline"
          label="合并报告"
          tooltip="把选中的案例，合并为同一个pdf报告并下载"
          @click="
            showReportBulkDownloadDialog(reportBulkDownloadTypeDict.mergedPdf)
          "
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-bookmark-plus-outline"
          label="批量备注"
          tooltip="批量设置选中行的备注，也可点击每行的备注单元格单独设置备注"
          @click="bulkSetCaseMark"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-file-excel-outline"
          label="导出Excel"
          tooltip="根据选中的案例导出Excel，包含因子分和原始答题数据"
          @click="extractCasesToExcel"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="error"
          icon="mdi-delete"
          label="批量删除"
          tooltip="删除所有选中的案例"
          @click="deleteCases(true)"
        />
        <v-spacer></v-spacer>
        <AppTooltipBtn
          text
          rounded
          btn-class="ml-5 my-2"
          color="green"
          icon="mdi-tune"
          :label="isShowFilterPanel ? '关闭筛选面板' : '打开筛选面板'"
          tooltip="打开或关闭筛选面板"
          @click="toggleFilterPanel"
        />
      </v-card-title>
      <v-slide-y-transition>
        <v-card-text ref="filterPanel" v-if="isShowFilterPanel">
          <div class="d-flex flex-wrap mx-2">
            <v-select
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="案例完成状态"
              :items="filterCaseStatusSelectItems"
              v-model="caseFilters.testStatusList"
            ></v-select>
            <v-select
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              :label="nodeColumnAlias"
              :items="filterableNodeNames"
              v-model="caseFilters.nodeNames"
            ></v-select>
            <v-select
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="量表"
              :items="filterableLbNames"
              v-model="caseFilters.lbNames"
            ></v-select>
            <AppDateRangePicker
              outlined
              label="测量日期"
              v-model="caseFilters.dateRange"
            />
            <v-select
              class="mr-3 mb-3 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="预警"
              :items="filterableAlertNames"
              v-model="caseFilters.alertNames"
            ></v-select>
            <v-text-field
              class="mr-3 mb-3 filter-item"
              dense
              single-line
              outlined
              clearable
              hide-details="auto"
              label="识别号"
              v-model="filterPersonIdentity"
            ></v-text-field>
            <template v-for="(personInfo, idx) in filterPersonInfoList">
              <v-text-field
                :key="idx"
                class="mr-3 mb-3 filter-item"
                dense
                single-line
                outlined
                clearable
                hide-details="auto"
                :label="personInfo.text"
                v-model="personInfo.value"
              ></v-text-field>
            </template>
            <AppMenuBtn
              color="primary"
              icon="mdi-filter-plus"
              tooltip="添加筛选"
            >
              <v-list>
                <v-list-item
                  v-for="(fieldText, idx) in filterPersonFieldTextList"
                  :key="idx"
                  @click="addOnePersonFilter(fieldText)"
                >
                  {{ fieldText }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
          </div>
          <AppTooltipBtn
            class="ml-6"
            color="primary"
            label="筛选案例"
            icon="mdi-filter"
            small
            small-icon
            @click="filterCaseList"
          />
          <AppTooltipBtn
            class="ml-6"
            text
            color="primary"
            label="重置"
            icon="mdi-refresh"
            small
            @click="resetCaseFilters"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-data-table
        class="mx-4"
        :height="getDataTableHeight()"
        :key="caseDataTableKey"
        v-model="caseListSelected"
        fixed-header
        :headers="caseListHeaders"
        :items="caseList"
        :server-items-length="serverCaseCount"
        :options.sync="serverOptions"
        item-key="guid"
        multi-sort
        show-select
        :single-select="false"
        :page.sync="caseListPageNum"
        :loading="isCaseListLoading"
        loading-text="正在读取案例列表，请稍候..."
        no-data-text="未找到任何案例"
        no-results-text="未找到任何匹配案例"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageAllText: '所有',
          itemsPerPageText: '每页案例数：',
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }"
        @item-selected="caseRowSelected"
        @toggle-select-all="caseToggleSelectAll"
      >
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{
            `共 ${itemsLength} 个案例，本页显示案例 ${pageStart}-${pageStop} ${selectedCaseCountText}`
          }}
        </template>
        <template v-slot:[`item.testPerson.loginIdentity`]="{ value }">
          <v-chip v-if="value" small>
            {{ `${value.loginKeyType.typeText}: ${value.loginKey}` }}
          </v-chip>
        </template>
        <template v-slot:[`item.caseMark`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.caseMark"
            large
            save-text="保存"
            cancel-text="取消"
            @save="singleSetCaseMark(item.guid, item.caseMark)"
          >
            <div>{{ item.caseMark }}</div>
            <template v-slot:input>
              <v-form ref="singleCaseMark">
                <div class="mt-4 text-h6">更新备注</div>
                <v-text-field
                  single-line
                  dense
                  autofocus
                  label="案例备注"
                  v-model="item.caseMark"
                  :rules="fieldRules.caseMark"
                ></v-text-field>
              </v-form>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.alertList`]="{ value }">
          <v-tooltip bottom v-for="(alert, idx) in value" :key="idx">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!!alert.displayName"
                :color="`${alert.colorHex}`"
                v-on="on"
                v-bind="attrs"
              >
                mdi-record
              </v-icon>
            </template>
            <span>{{ alert.displayName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ value }">
          <span>{{
            filterCaseStatusDict[value] && filterCaseStatusDict[value].text
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <ReportModeMenuBtn
            v-if="item.status === filterCaseStatusDict.completed.value"
            v-model="selectedReportMode"
            :lb-id="item.lbId"
            btn-class="mr-2"
            color="primary"
            icon="mdi-file-document-outline"
            tooltip="查看案例报告"
            @click="showAdminReport(item)"
          />
          <AppTooltipBtn
            v-if="item.status === filterCaseStatusDict.completed.value"
            btn-class="mr-2"
            color="primary"
            icon="mdi-file-table-outline"
            tooltip="查看原始答题数据"
            @click="showAnswerDetails(item)"
          />
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-account-edit"
            tooltip="修改案例的个人信息"
            @click="editOneCase(item)"
          />
          <AppTooltipBtn
            color="red"
            icon="mdi-delete"
            tooltip="删除这个案例"
            @click="deleteCases(false, item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowEditDialog"
      persistent
      size="small"
      overflow-height="calc(100vh - 270px)"
      title="编辑案例个人信息"
      text-class="px-10"
      color="green"
      action-text="保存"
      :loading="isBtnLoading"
      @confirm="editOneCaseConfirmed"
      @closed="caseEditDialogClosed"
    >
      <PersonInfoEditor
        :node-guids="nodeGuids"
        :region-guid="regionGuid"
        v-model="editingCasePerson"
        :no-update-fields="casePersonNoUpdateFields"
        @update:no-update-fields="casePersonNoUpdateFields = $event"
        :field-config-list="userFieldConfigList"
        :identity-disabled="true"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteDialog"
      size="small"
      :title="caseDeleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isBtnLoading"
      @confirm="deleteCasesConfirmed"
      @closed="caseDeleteDialogClosed"
    >
      删除后不可恢复
    </AppDialog>
    <AppDialog
      v-model="isShowCaseMarkDialog"
      size="small"
      title="批量设置备注"
      text-class="px-10"
      color="success"
      action-text="确定"
      :loading="isBtnLoading"
      @confirm="bulkSetCaseMarkConfirmed"
      @closed="caseMarkDialogClosed"
    >
      <v-text-field
        label="备注"
        v-model="newCaseMark"
        clearable
        :rules="fieldRules.caseMark"
      ></v-text-field>
    </AppDialog>
    <AppDialog v-model="isShowHelpDialog" title="团体报告帮助">
      <p class="font-weight-bold">点击“团体报告”按钮查看团体报告</p>
      <h4>生成条件：</h4>
      <p>需要选中6个或以上，基于相同量表的测试结果</p>
      <p>注：仅有部分量表可以生成“团体报告”</p>
    </AppDialog>
    <ReportDisplayerDialog
      v-model="isShowAdminReportDialog"
      :displayer-type="reportDisplayerType"
      :case-guid-list="reportCaseGuidList"
      :report-mode="selectedReportMode"
      @closed="closeAdminReportDialog"
    />
    <ReportBulkDownloadDialog
      v-model="isShowReportBulkDownloadDialog"
      :download-type="reportBulkDownloadType"
      :case-guid-list="caseGuidListSelected"
      @closed="closeReportBulkDownloadDialog"
    />
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import PersonInfoEditor from "@/components/PersonInfoEditor";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import ReportDisplayerDialog from "@/components/report/ReportDisplayerDialog";
import ReportBulkDownloadDialog from "@/components/report/ReportBulkDownloadDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import ReportModeMenuBtn from "@/components/report/ReportModeMenuBtn";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  fetchCaseList,
  fetchCaseGuidList,
  fetchFilterableLbNames,
  deleteCases,
  saveCaseMark,
  extractExcelFromCases
} from "@/api/case";
import { fetchTestPersonDetails, savePersonBasicInfo } from "@/api/person";
import { fetchManageNodeFlatSelectList } from "@/api/manageNode";
import { getAlertNamesForFilter } from "@/api/lb";
import { reportDownloadTypeDict } from "@/utils/constants/report";
import { caseStatusDict } from "@/utils/constants/case";
import { getDateFromDateTime } from "@/utils/dateTime";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { downloadFile } from "@/utils/download";
import {
  getStartDateFromDateRange,
  getEndDateFromDateRange
} from "@/utils/dateTime";
import {
  buildLimitOffsetObj,
  buildOrderList
} from "@/utils/backendPagingUtils";
import { simpleDeepCopy } from "@/utils/objectUtils";

export default {
  components: {
    PersonInfoEditor,
    AppLoadingSpinner,
    AppDialog,
    ReportDisplayerDialog,
    ReportBulkDownloadDialog,
    AppMessageBox,
    AppTooltipBtn,
    AppMenuBtn,
    ReportModeMenuBtn,
    AppDateRangePicker
  },

  data() {
    return {
      // loading
      isCaseListLoading: false,
      isBtnLoading: false,
      isLoading: false,
      isInitCaseList: false,
      caseDataTableKey: 0,
      // case list
      caseList: [],
      caseListSelected: [],
      caseSelectAll: false,
      caseGuidListSelected: [],
      userFieldConfigList: [],
      caseListHeaders: [],
      caseListPageNum: 1,
      serverCaseCount: 0,
      serverOptions: {},
      // filters
      isShowFilterPanel: true,
      caseFilters: {
        testStatusList: [],
        nodeNames: [],
        lbNames: [],
        alertNames: [],
        dateRange: []
      },
      filterableNodeNames: [],
      filterableLbNames: [],
      filterableAlertNames: [],
      filterPersonIdentity: "",
      filterPersonFieldTextList: [],
      filterPersonInfoList: [],
      // dialogs
      errorMsg: "",
      isShowFilterDateRangeDialog: false,
      isShowHelpDialog: false,
      isShowEditDialog: false,
      isShowDeleteDialog: false,
      isCaseBulkDelete: false,
      isShowAdminReportDialog: false,
      isShowReportBulkDownloadDialog: false,
      isShowGroupReportHelpDialog: false,
      isShowCaseMarkDialog: false,
      reportDisplayerType: "",
      // report
      selectedReportMode: "",
      reportCaseGuidList: [],
      reportBulkDownloadType: "",
      reportBulkDownloadTypeDict: reportDownloadTypeDict,
      // actions
      actionCaseIndex: -1,
      actionCaseGuid: "",
      actionCaseReportId: "",
      actionCasePerson: {},
      editingCasePerson: {},
      casePersonNoUpdateFields: [],
      newCaseMark: "",
      // rules
      fieldRules: {
        caseMark: [val => (val || "").length <= 30 || "备注不能超过40个字符"]
      }
    };
  },

  watch: {
    serverOptions: {
      handler() {
        // 初始化的时候不自动刷新
        if (!this.isInitCaseList) {
          this.fetchCaseListData();
        }
      },
      deep: true
    },
    "caseFilters.testStatusList": {
      handler() {
        this.resetFilterSelectList();
      }
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    selectedCaseLength() {
      if (this.caseGuidListSelected) {
        return this.caseGuidListSelected.length;
      }
      return 0;
    },
    selectedCaseCountText() {
      return this.selectedCaseLength > 0
        ? `，  选中了${this.selectedCaseLength}个案例`
        : "";
    },
    caseDeleteDialogTitle() {
      if (this.isCaseBulkDelete) {
        return `确定要删除选中的 ${this.selectedCaseLength} 个案例吗？`;
      }
      return `确定要删除案例 ${this.actionCaseReportId} 吗？`;
    },
    nodeColumnAlias() {
      let nodeFieldConfig = this.userFieldConfigList.filter(
        field => field.fieldName === "nodeGuid"
      );
      if (nodeFieldConfig && nodeFieldConfig.length) {
        return nodeFieldConfig[0].fieldAlias;
      }
      return "";
    },
    filterCaseStatusDict() {
      return caseStatusDict;
    },
    filterCaseStatusSelectItems() {
      return _.map(caseStatusDict, cs => cs);
    },
    filterHasCompletedCaseStatus() {
      let hasCompletedStatus = this.caseFilters.testStatusList.includes(
        this.filterCaseStatusDict.completed.value
      );
      let hasNoStatus = this.caseFilters.testStatusList.length < 1;
      return hasCompletedStatus || hasNoStatus;
    },
    filterHasOnlyOneCaseStatus() {
      return this.caseFilters.testStatusList.length === 1;
    }
  },

  methods: {
    // ============================ DataTable fit =================================
    getCardTitleHeight() {
      if (this.$refs.cardTitle) {
        return this.$refs.cardTitle.offsetHeight;
      }
      return 0;
    },
    getFilterPanelHeight() {
      if (this.$refs.filterPanel) {
        return this.$refs.filterPanel.offsetHeight;
      }
      return 0;
    },
    getDataTableHeight() {
      var overflowHeight =
        200 + this.getCardTitleHeight() + this.getFilterPanelHeight();
      return `calc(100vh - ${overflowHeight}px)`;
    },
    // ============================ Fetch Data =================================
    buildCaseFilterObj() {
      let personInfoList = simpleDeepCopy(this.filterPersonInfoList || []);
      // 添加用户识别号的筛选
      if (this.filterPersonIdentity) {
        personInfoList.push({
          field: "loginIdentity",
          text: "",
          value: {
            loginKey: this.filterPersonIdentity
          }
        });
      }
      return {
        testStatusList: this.caseFilters.testStatusList,
        lbNames: this.caseFilters.lbNames,
        nodeNames: this.caseFilters.nodeNames
          ? this.caseFilters.nodeNames.map(dName =>
              dName === "无" ? null : dName
            )
          : [],
        alertDispNames: this.caseFilters.alertNames
          ? this.caseFilters.alertNames.map(dName =>
              dName === "无预警" ? null : dName
            )
          : [],
        testDateStart: getStartDateFromDateRange(this.caseFilters.dateRange),
        testDateEnd: getEndDateFromDateRange(this.caseFilters.dateRange),
        personInfoList
      };
    },
    async fetchCaseListData() {
      try {
        this.isCaseListLoading = true;
        let { sortBy, sortDesc, page, itemsPerPage } = this.serverOptions;
        let pagedCases = await fetchCaseList(
          this.regionGuid,
          this.nodeGuids,
          this.buildCaseFilterObj(),
          buildLimitOffsetObj(page, itemsPerPage),
          buildOrderList(sortBy, sortDesc)
        );
        this.caseList = pagedCases.caseList;
        this.serverCaseCount = pagedCases.count;
        if (this.caseSelectAll) {
          this.caseListSelected = this.caseList;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    async fetchUserFieldConfigList() {
      try {
        this.isCaseListLoading = true;
        this.userFieldConfigList = await getUserFieldConfig(this.nodeGuids);
        this.refreshPersonFilterTextList();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    async fetchFilterableNodeNames() {
      try {
        this.isLoading = true;
        var nodeFlatList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.nodeGuids
        );
        this.filterableNodeNames = nodeFlatList.map(node => node.text);
        this.filterableNodeNames.push("无");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async fetchFilterableLbNames() {
      try {
        this.isLoading = true;
        this.filterableLbNames = await fetchFilterableLbNames(
          this.regionGuid,
          this.nodeGuids,
          this.caseFilters.testStatusList
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async fetchFilterableAlertNames() {
      try {
        this.isLoading = true;
        this.filterableAlertNames = await getAlertNamesForFilter(
          this.regionGuid,
          this.nodeGuids,
          this.caseFilters.testStatusList
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    resetCaseListHeaders() {
      this.caseListHeaders = [{ text: "编号", value: "reportId" }];
      for (let fieldConfig of this.userFieldConfigList) {
        // 对 nodeGuid 来说，直接使用 nodeName 是最高效的，可以不用再次获取 nodeSelectList
        let fieldName =
          fieldConfig.fieldName === "nodeGuid"
            ? "nodeName"
            : fieldConfig.fieldName;
        if (fieldConfig.isVisibleInTable) {
          if (fieldConfig.fieldName === "nodeGuid") {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          } else {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          }
        }
      }
      this.caseListHeaders.push({
        text: "识别号",
        value: "testPerson.loginIdentity"
      });
      this.caseListHeaders.push({
        text: "量表",
        value: "lbDispName"
      });
      if (this.filterHasCompletedCaseStatus) {
        // 没有已完成的情况下，不显示测量日期和预警
        this.caseListHeaders.push({
          text: "测量日期",
          value: "testDate"
        });
        this.caseListHeaders.push({ text: "备注", value: "caseMark" });
        this.caseListHeaders.push({
          text: "预警",
          value: "alertList"
        });
      }
      if (!this.filterHasOnlyOneCaseStatus) {
        // 只有已完成的情况下，不显示状态
        this.caseListHeaders.push({
          text: "完成状态",
          value: "status"
        });
      }
      this.caseListHeaders.push({
        text: "操作",
        value: "actions",
        sortable: false
      });
    },
    // ============================ Case Filters =================================
    toggleFilterPanel() {
      this.isShowFilterPanel = !this.isShowFilterPanel;
      // 刷新 dataTable 的显示高度
      this.caseDataTableKey++;
    },
    addOnePersonFilter(fieldText) {
      let matchedFieldConfig = this.userFieldConfigList.find(
        fc => fc.fieldAlias === fieldText
      );
      if (matchedFieldConfig && matchedFieldConfig.fieldName) {
        this.filterPersonInfoList.push({
          text: fieldText,
          field: matchedFieldConfig.fieldName,
          value: ""
        });
        // 在 MenuBtn 中删除已添加的 field
        let fieldTextIdx = this.filterPersonFieldTextList.findIndex(
          pText => pText === fieldText
        );
        this.filterPersonFieldTextList.splice(fieldTextIdx, 1);
      }
    },
    refreshPersonFilterTextList() {
      if (this.userFieldConfigList) {
        // 添加 PersonInfo 筛选的 MenuBtn
        this.filterPersonFieldTextList = this.userFieldConfigList
          .filter(
            field =>
              field.fieldName !== "age" &&
              field.fieldName !== "nodeGuid" &&
              field.isVisibleInTable
          )
          .map(field => field.fieldAlias);
      }
    },
    filterCaseList() {
      // 筛选前清空选择，并重置到第一页
      this.caseListSelected = [];
      this.caseSelectAll = false;
      this.caseGuidListSelected = [];
      this.caseListPageNum = 1;
      this.resetCaseListHeaders();
      return this.fetchCaseListData();
    },
    resetServerOptions() {
      this.serverOptions.sortBy = ["testDate"];
      this.serverOptions.sortDesc = [true];
    },
    resetFilterSelectList() {
      this.fetchFilterableNodeNames();
      this.fetchFilterableLbNames();
      this.fetchFilterableAlertNames();
    },
    async resetCaseFilters() {
      // 筛选前清空选择
      this.caseFilters = {
        testStatusList: [],
        lbNames: [],
        nodeNames: [],
        alertNames: [],
        dateRange: []
      };
      this.filterPersonIdentity = "";
      this.resetPersonFilterFieldSelected();
      return this.filterCaseList();
    },
    resetPersonFilterFieldSelected() {
      this.filterPersonInfoList = [];
      this.refreshPersonFilterTextList();
      this.caseFilters.testStatusList = [
        this.filterCaseStatusDict.completed.value
      ];
    },
    // ============================ Case Actions - Select =================================
    caseRowSelected({ item, value }) {
      // 选中单行时，取消全选
      this.caseSelectAll = false;
      let curSelGuidList = this.caseListSelected.map(c => c.guid);
      if (value) {
        curSelGuidList.push(item.guid);
      } else {
        let deleteIndex = curSelGuidList.findIndex(
          cGuid => cGuid === item.guid
        );
        curSelGuidList.splice(deleteIndex, 1);
      }
      this.caseGuidListSelected = curSelGuidList;
    },
    caseToggleSelectAll({ value }) {
      if (value) {
        this.caseSelectAll = true;
        this.setSelectAllCaseGuidList();
      } else {
        this.caseSelectAll = false;
        this.caseListSelected = [];
        this.caseGuidListSelected = [];
      }
    },
    async setSelectAllCaseGuidList() {
      try {
        this.isLoading = true;
        this.caseGuidListSelected = await fetchCaseGuidList(
          this.regionGuid,
          this.nodeGuids,
          this.buildCaseFilterObj()
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ Case Actions - Case Mark =================================
    singleSetCaseMark(caseGuid, newMark) {
      let isRulePassed = this.$refs.singleCaseMark.validate();
      if (isRulePassed) {
        this.updateCaseMark([caseGuid], newMark);
      }
    },
    bulkSetCaseMark() {
      if (this.selectedCaseLength) {
        this.isShowCaseMarkDialog = true;
      }
    },
    caseMarkDialogClosed() {
      this.newCaseMark = "";
    },
    async bulkSetCaseMarkConfirmed() {
      this.isBtnLoading = true;
      await this.updateCaseMark(this.caseGuidListSelected, this.newCaseMark);
      // 更新显示的列表
      this.caseList.map(c => {
        if (this.caseGuidListSelected.includes(c.guid)) {
          c.caseMark = this.newCaseMark;
        }
        return c;
      });
      this.isShowCaseMarkDialog = false;
      this.isBtnLoading = false;
    },
    async updateCaseMark(caseList, newMark) {
      try {
        this.isLoading = true;
        await saveCaseMark(caseList, newMark);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ Case - Delete =================================
    deleteCases(isBulkDelete, itemToDelete = null) {
      this.isCaseBulkDelete = isBulkDelete;
      if (isBulkDelete && this.selectedCaseLength) {
        this.isShowDeleteDialog = true;
      } else if (!isBulkDelete && itemToDelete) {
        this.assignActionCase(itemToDelete);
        this.isShowDeleteDialog = true;
      }
    },
    async deleteCasesConfirmed() {
      try {
        this.isBtnLoading = true;
        let caseGuidsToDelete = [];
        if (this.isCaseBulkDelete && this.selectedCaseLength) {
          caseGuidsToDelete = this.caseGuidListSelected;
        } else if (!this.isCaseBulkDelete && this.actionCaseGuid) {
          caseGuidsToDelete = [this.actionCaseGuid];
        }
        let caseGuidsDeleted = await deleteCases(caseGuidsToDelete);
        // 更新显示的列表
        if (caseGuidsDeleted && caseGuidsDeleted.length) {
          this.caseList = this.caseList.filter(
            c => !caseGuidsDeleted.includes(c.guid)
          );
        }
        this.isShowDeleteDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    caseDeleteDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
    },
    // ============================ Case Item Actions - Edit =================================
    assignActionCase(caseItem) {
      this.actionCaseIndex = caseItem ? this.caseList.indexOf(caseItem) : -1;
      this.actionCaseGuid = caseItem ? caseItem.guid : "";
      this.actionCasePerson = caseItem ? caseItem.testPerson : {};
      this.actionCaseReportId = caseItem ? caseItem.reportId : "";
    },
    async editOneCase(item) {
      this.assignActionCase(item);
      await this.resetCasePersonDetails(item.testPerson.guid);
      this.isShowEditDialog = true;
    },
    async resetCasePersonDetails(personGuid) {
      try {
        this.isLoading = true;
        this.editingCasePerson = await fetchTestPersonDetails(personGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async editOneCaseConfirmed() {
      try {
        this.isBtnLoading = true;
        await savePersonBasicInfo(
          { ...this.editingCasePerson },
          this.casePersonNoUpdateFields
        );
        // 更新显示的列表
        let casesToUpdate = this.caseList.filter(
          c => c.testPerson.guid === this.editingCasePerson.guid
        );
        for (let c of casesToUpdate) {
          Object.assign(c, { testPerson: this.editingCasePerson });
        }
        this.isShowEditDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    caseEditDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
      this.editingCasePerson = {};
    },
    // ============================ Case Item Actions - Report =================================
    showAdminReport(item) {
      this.assignActionCase(item);
      this.reportDisplayerType = "admin";
      this.reportCaseGuidList = [this.actionCaseGuid];
      this.isShowAdminReportDialog = true;
    },
    showGroupReport() {
      if (this.selectedCaseLength) {
        this.reportDisplayerType = "group";
        this.reportCaseGuidList = this.caseGuidListSelected;
        this.isShowAdminReportDialog = true;
      }
    },
    showAnswerDetails(item) {
      this.assignActionCase(item);
      this.reportDisplayerType = "answer";
      this.reportCaseGuidList = [this.actionCaseGuid];
      this.isShowAdminReportDialog = true;
    },
    closeAdminReportDialog() {
      this.isShowAdminReportDialog = false;
      this.$nextTick(() => {
        this.assignActionCase(null);
        this.reportCaseGuidList = [];
        this.reportDisplayerType = "";
        this.selectedReportMode = "";
      });
    },
    // ============================ 批量下载 pdf (zip/pdf) =================================
    showReportBulkDownloadDialog(downloadType) {
      if (this.selectedCaseLength) {
        this.reportBulkDownloadType = downloadType;
        this.isShowReportBulkDownloadDialog = true;
      }
    },
    closeReportBulkDownloadDialog() {
      this.isShowReportBulkDownloadDialog = false;
      this.reportBulkDownloadType = "";
    },
    // ============================ 导出 Excel =================================
    async extractCasesToExcel() {
      if (this.selectedCaseLength) {
        try {
          this.isLoading = true;
          let downloadPath = await extractExcelFromCases(
            this.regionGuid,
            this.nodeGuids,
            this.caseGuidListSelected,
            false
          );
          downloadFile(
            downloadPath,
            `案例结果导出_${getDateFromDateTime(new Date().toISOString())}.xlsx`
          );
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    }
  },

  async created() {
    this.isInitCaseList = true;
    await this.fetchUserFieldConfigList();
    // 初始化sort
    this.resetServerOptions();
    // 初始化filter，并获取 CaseList
    this.resetFilterSelectList();
    await this.resetCaseFilters();
    this.isInitCaseList = false;
  }
};
</script>

<style lang="scss" scoped>
$text-field-outlined-prepend-append-margin-top: 0px;
.fixed-card {
  height: calc(100vh - 125px);
}
.filter-item {
  max-width: 300px;
  min-width: 150px;
}
.filter-item-person {
  padding-left: 0px;
}
.filter-item-inner-select {
  max-width: 100px;
  min-width: 50px;
  margin: -8px 12px 1px -12px;
}
</style>
